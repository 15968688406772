<template>
    <div id="login">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="login-content d-flex row">
                        <div class="col-md-6 pr-0 order-1 order-md-0">
                            <div v-bind:style="{ backgroundImage: 'url(' + require('@/assets/images/banner-login.png') + ')' }" class="login-banner d-flex">
                                <div class="align-self-center">
                                    Membangun kebersamaan  dalam menghasilkan Co-Creation dan Collaboration
                                </div>
                            </div>
                        </div>
                        <div class="login-form col-md-6 order-0 order-md-1">
                            <h3>Daftar</h3>
                            <p>Silahkan masukkan data yang sesuai</p>
                            <br><br>
                            <transition name="fade" mode="out-in">
                            <ul class="register-type" v-if="selected === null">
                                <li @click="selected = 'mahasiswa'" :class="{selected:selected == 'mahasiswa'}">
                                    <table>
                                        <tr>
                                            <td><span class="icon-type"><Icon icon="fa-solid:user" /></span></td>
                                            <td><strong>Mahasiswa</strong><h4>Daftar sebagai Mahasiswa</h4></td>
                                        </tr>
                                    </table>
                                </li>
                                <li @click="selected = 'dosen'" :class="{selected:selected == 'dosen'}">
                                    <table>
                                        <tr>
                                            <td><span class="icon-type"><Icon icon="fa-solid:user-tie" /></span></td>
                                            <td><strong>Dosen</strong><h4>Daftar sebagai Dosen</h4></td>
                                        </tr>
                                    </table>
                                </li>
                                <li @click="selected = 'alumni'" :class="{selected:selected == 'alumni'}">
                                    <table>
                                        <tr>
                                            <td><span class="icon-type"><Icon icon="fa-solid:user-graduate" /></span></td>
                                            <td><strong>Alumni</strong><h4>Daftar sebagai Alumni</h4></td>
                                        </tr>
                                    </table>
                                </li>
                            </ul>
                            <form v-if="selected != null" @submit.prevent="actionRegister()">
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text" v-model="firstname" @input="checkForInput" class="form-control" placeholder="Nama Depan">
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" v-model="lastname" @input="checkForInput" class="form-control" placeholder="Nama Belakang">
                                    </div>
                                </div>
                                <input type="text" v-model="username" @input="checkForInput" class="form-control" placeholder="Username">
                                <input type="email" v-model="email" @input="checkForInput" class="form-control" placeholder="Email">
                                <input type="text" v-model="generation" @input="checkForInput" class="form-control" placeholder="Angkatan" v-if="selected != 'dosen'">
                                <input type="text" v-model="no_induk" v-if="selected === 'mahasiswa'" @input="checkForInput" class="form-control" placeholder="Nomor Induk Mahasiswa">
                                <input type="text" v-model="no_induk" v-if="selected === 'dosen'" @input="checkForInput" class="form-control" placeholder="Nomor Induk Dosen Nasional">
                                <input type="password" v-model="password" @input="checkForInput" class="form-control" placeholder="Kata Sandi">
                                <vue-recaptcha sitekey="6Lc0NtEiAAAAAOqY-YFXkvcE7bjvQ1yixYvtBz7N" @verify="onVerify"></vue-recaptcha>
                                <br>
                                <b-form-checkbox name="checkbox-1" value="1" unchecked-value="" v-model="terms" @change="checkForInput">
                                    Saya menyetujui <a href="/terms-condition" target="_blank">Syarat dan Ketentuan</a> dan <a href="/privacy-policy" target="_blank">Kebijakan Privasi</a> yang berlaku.
                                </b-form-checkbox><br>
                                <button class="btn btn-primary fullsize-btn" :class="disabled">Daftar</button>
                            </form>
                            </transition>
                            <br><br>
                            <center>Sudah punya akun? <router-link to="/login"><b>Masuk</b></router-link></center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import { Icon } from '@iconify/vue2'
    import { VueRecaptcha } from 'vue-recaptcha'

    export default {
        name: 'Register',
        components: {
            Icon,
            VueRecaptcha
        },
        data () {
            return {
                username: '',
                password: '',
                firstname: '',
                lastname: '',
                email: '',
                terms: '',
                generation: '',
                no_induk: '',
                selected: null,
                captcha: false,
                disabled: 'disabled'
            }
        },
        methods: {
            async actionRegister () {
                const response = await axios.post('register', {
                    username: this.username,
                    password: this.password,
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email,
                    generation: this.generation,
                    no_induk: this.no_induk,
                    type: this.selected
                })
                if (response.data.status) {
                    localStorage.setItem('token', response.data.user.token)
                    this.$router.go('/verify')
                } else {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message
                    })
                }
            },
            checkForInput () {
                if (this.captcha && this.terms !== '' && this.username !== '' && this.password !== '' && this.email !== '' && this.firstname !== '') {
                    this.disabled = ''
                } else {
                    this.disabled = 'disabled'
                }
            },
            onVerify: function () {
                this.captcha = true
                this.checkForInput()
            }
        }
    }
</script>
